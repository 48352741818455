<template>
  <div>
    <nav>
      <ul class="pagination--container">
        <li class="page-item" :class="{disabled: firstPage}" v-if="currentPage > limitPages">
          <a class="page-link action d-block d-md-none" @click="$emit('page-changed', currentPage -1)">
            <img src="@/assets/icons/arrow_left_green.svg" alt="Anterior" />
          </a>
          <a class="page-link action d-none d-md-block" @click="$emit('page-changed', currentPage -1)">
            <img src="@/assets/icons/arrow_left_green.svg" alt="Anterior" /> Anterior
          </a>
        </li>
        <li class="page-item" v-if="(currentPage > limitPages)">
          <a class="page-link action d-none d-md-block" @click="less">Menos</a>
          <a class="page-link action d-block d-md-none text-success" @click="less">-</a>
        </li>

        <li v-for="page in nextBlock" v-if="page <= pages" :key="page" class="page-item" :class="{active: page === currentPage}">
          <a @click="$emit('page-changed', page)" class="page-link">{{ page }}</a>
        </li>

        <li class="page-item" v-if="(!nextBlock.includes(pages))">
          <a @click="more" class="page-link action d-none d-md-block">Mais</a>
          <a @click="more" class="page-link action d-block d-md-none text-success">+</a>
        </li>
        <li class="page-item" :class="{disabled: lastPage}" v-if="!nextBlock.includes(pages)">
          <a @click="$emit('page-changed', currentPage + 1)" class="page-link action d-block d-md-none">
            <img src="@/assets/icons/arrow_right_green.svg" alt="Anterior" />
          </a>
          <a @click="$emit('page-changed', currentPage + 1)" class="page-link action d-none d-md-block">
            Próxima <img src="@/assets/icons/arrow_right_green.svg" alt="Anterior" />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'paginator',
  props: {
    currentPage: {type: Number, default: 1},
    pages: {type: Number, default: 0},
    limitPages: {type: Number, default: 3},
  },
  methods: {
    createRange(start, end) {
      const range = [];

      for(let i = start; i <= end; i++ ) {
        range.push(i);
      }

      return range;
    },
    getNearestModule() {
      for(let i = this.currentPage; i < this.currentPage + this.limitPages; i++) {
        if(i % this.limitPages === 0) {
          return i;
        }
      }

      return 1;
    },
    less() {
      if(this.currentPage === this.limitPages) {
        this.$emit('page-changed', 1)
      } else {
        this.$emit('page-changed', this.nextBlock[0] - this.limitPages)
      }
    },
    more() {
      this.$emit('page-changed', this.nextBlock[this.nextBlock.length - 1] + 1)
    }
  },
  computed: {
    firstPage() {
      return Boolean(this.currentPage <= 1)
    },

    lastPage() {
      return Boolean(this.currentPage >= this.pages)
    },
    nextBlock() {
      let minor = (this.currentPage) <= this.limitPages;

      if(minor) {
        return this.createRange(1, this.limitPages);
      }

      let nearestModule = this.getNearestModule();
      return this.createRange(nearestModule - (this.limitPages - 1), nearestModule);
    }
  }
}
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}

.pagination--container {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination--container .page-link {
  border-radius: .32rem !important;
  margin: 0 .1rem;
  padding: .5rem;
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #ccc;
  font-weight: 500;

  &.action {
    font-weight: 600;
  }

  img {
    width: 12px;
    height: 10px;
    margin-top: -2px;
  }
}

.pagination--container .page-link:hover {
  color: var(--success);
}

.pagination--container .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--success);
  border-color: var(--success);
}
</style>
