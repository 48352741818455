<template>
  <div>
    <div class="d-flex align-items-center justify-content-between flex-wrap w-100 mb-3 mb-md-0">
      <div>
        <div class="d-none d-md-block">
          <breadcrumbs>
            <slot name="breadcrumbs"></slot>
          </breadcrumbs>
        </div>

        <slot name="title">
          <h2 class="title"><strong>{{ title }}</strong></h2>
        </slot>
      </div>

      <slot name="actions">
        <button class="d-none d-lg-block btn btn-outline-primary" @click.prevent="back">Voltar</button>
      </slot>
    </div>

    <hr class="mt-3 mb-4 d-none d-md-block">
  </div>
</template>
<script>
import Breadcrumbs from "@/components/breadcrumbs/Index.vue";

export default {
  name: 'pageHeader',
  props: {
    title: ''
  },
  components: {
    Breadcrumbs
  },
  methods: {
    back() {
      window.history.back();
      this.$emit('back');
    }
  }
}
</script>
<style scoped lang="scss">
.title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -.02rem;
  margin-top: 10px;
  color: var(--primary);

  @media (min-width: 768px) {
    font-size: 24px;
  }
}
</style>
