<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Portais`">
      <template v-slot:breadcrumbs>
        <li>Portais</li>
      </template>

      <template v-slot:actions>
        <div>
          <button class="btn btn-success" @click="clearFields" data-bs-toggle="modal" data-bs-target="#portal-modal">Novo Portal</button>
        </div>
      </template>
    </page-header>

    <filters @search="handleSearch(filters, 1)" @clear="clearFilters">
      <form @keyup.enter="handleSearch(filters)">
        <div class="row g-2 mt-2">
          <div class="col-4">
            <div>
              <label class="form-label">Nome</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.name">
            </div>
          </div>

          <div class="col-2">
            <label class="form-label">Padrão</label>
            <select class="form-select form-select-sm" v-model="filters.spreader">
              <option value="" selected>Todos</option>
              <option v-for="spreader in spreaders" :key="spreader.id" :value="spreader.id">{{ spreader.name }}</option>
            </select>
          </div>

          <div class="col-2">
            <label class="form-label">Situação</label>
            <select class="form-select form-select-sm" v-model="filters.status">
              <option value="">Todos</option>
              <option value="1">Ativos</option>
              <option value="0">Inativos</option>
            </select>
          </div>
        </div>
      </form>
    </filters>

    <div class="card bg-white mt-4">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-end">
          <span class="small text-secondary" v-if="collection.data.length">
            Mostrando {{ collection.data.length }} de {{ collection.meta.total }} registros
          </span>
        </div>
        <div class="table-responsive mt-3">

          <table class="table table-striped" v-if="collection.data.length">
            <thead>
            <tr>
              <th>Nome</th>
              <th class="text-center">Padrão de XML</th>
              <th class="text-center">Situação</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="portal in collection.data" :key="portal.id">
              <td>{{ portal.name }}</td>
              <td class="text-center">{{ portal.spreader.name }}</td>
              <td class="text-center">
                <span class="badge bg-danger" v-if="portal.status === 0">Inativo</span>
                <span class="badge bg-success" v-else>Ativo</span>
              </td>
              <th>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                            data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                      Ações
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#" @click.prevent="edit(portal.id)">Editar</a></li>
                      <li><a class="dropdown-item" href="#" @click.prevent="remove(portal.id)">Remover</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li v-if="portal.status"><a class="dropdown-item text-danger" href="#"  @click.prevent="toggleStatus(portal)">Inativar</a></li>
                      <li v-else><a class="dropdown-item text-success" href="#" @click.prevent="toggleStatus(portal)">Ativar</a></li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
            </tbody>
          </table>

          <div class="bg-light p-2 rounded-2 text-center" v-else>Nenhum registro encontrado...</div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <paginator
                :current-page="collection.meta.current_page"
                :limit-pages="5"
                :pages="collection.meta.last_page"
                @page-changed="handleSearch(filters, $event)"
                v-if="collection.data.length"
            />
          </div>
        </div>
      </div>
    </div>

    <modal id="portal-modal" :custom-classes="['modal-dialog-centered', 'modal-md']" title="Portal">
      <template #body>
        <div class="row g-2">
          <div class="col-12">
            <label class="form-label">Nome</label>
            <input
                class="form-control"
                v-model="$v.payload.name.$model"
                :class="{ 'is-invalid' : $v.payload.name.$error }"
            />
            <div class="invalid-feedback" v-if="$v.payload.name.$error">Campo obrigatório</div>
          </div>

          <div class="col-12">
            <label class="form-label">Padrão de XML</label>
            <select
                class="form-select"
                v-model="$v.payload.spreader_id.$model"
                :class="{ 'is-invalid' : $v.payload.spreader_id.$error }"
            >
              <option v-for="spreader in spreaders" :key="spreader.id" :value="spreader.id">{{spreader.name}}</option>
            </select>
            <div class="invalid-feedback" v-if="$v.payload.spreader_id.$error">Campo obrigatório</div>
          </div>


        </div>
      </template>

      <template #footer>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" ref="closeModal">Cancelar</button>
        <button type="button" class="btn btn-primary" @click.prevent="handleSave">Salvar</button>
      </template>
    </modal>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import Paginator from "@/components/shared/paginator.vue";
import Loader from "@/components/shared/loader.vue";
import axios from "axios";
import {mapActions, mapState} from "vuex";
import {filter} from "core-js/internals/array-iteration";
import Filters from "@/components/shared/Filters.vue";
import Modal from "@/components/shared/Modal.vue";
import {required} from "vuelidate/lib/validators";
import {Modal as bsModal} from "bootstrap";

export default {
  name: 'clients',
  props: {},
  components: {
    Modal,
    Filters,
    Loader,
    Paginator,
    PageHeader
  },
  data() {
    return {
      modal: null,
      loading: false,
      filters: {
        name: null,
        status: 1,
        spreader: ''
      },
      payload: {
        id: null,
        name: null,
        spreader_id: null
      }
    }
  },
  validations: {
    payload: {
      name: { required },
      spreader_id: { required }
    }
  },
  async mounted() {
    await this.handleSearch(this.filters);
    await this.getSpreaders();
    this.modal = bsModal.getOrCreateInstance(document.getElementById('portal-modal'));
  },
  methods: {
    filter,
    ...mapActions('Portals', ['getAll', 'getSpreaders', 'save']),
    async handleSearch(filters, page = 1) {
      this.loading = true;
      const params = {
        page: page,
        ...filters
      }

      await this.getAll(params).finally(() => {
        this.loading = false;
      })
    },
    async handleSave() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return false;
      }

      this.loading = true;
      if(!this.payload.id) {
        await this.save(this.payload).then(() => {
          this.handleSearch({}, 1);
          this.$refs.closeModal.click();
        }).finally(() => {
          this.loading = false
        });
      } else {
        const formData = this.$serialize(this.payload);
        formData.append('_method', 'PUT');
        await axios.post(`/admin/portal/${this.payload.id}`, formData).then(() => {
          this.handleSearch({}, 1);
          this.$refs.closeModal.click();
        }).finally(() => {
          this.loading = false
        });
      }
    },
    async edit(id) {
      this.loading = true;
      await axios.get(`/admin/portal/${id}`).then(response => {
        const { data } = response;
        this.payload.id = data.id;
        this.payload.spreader_id = data.spreader_id;
        this.payload.name = data.name;

        this.modal.show();

      }).finally(() => {
        this.loading = false;
      })
    },
    async remove(id) {
      await this.$swal.fire({
        text: 'Confirma a exclusão do registro selecionado?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.delete(`/admin/portal/${id}`).then(() => {
            this.handleSearch({}, 1);
          }).catch(() => {
            this.$swal({
              icon: 'error',
              text: 'Esse portal já está em uso por algum cliente e não pode ser removido.'
            })
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    clearFilters() {
      this.filters = {
        name: null,
        status: 1,
        spreader: ''
      }

      this.handleSearch(this.filters, 1);
    },
    clearFields() {
      this.payload = {
        id: null,
        spreader_id: null,
        name: null
      }
      this.$v.$reset();
    },
    async toggleStatus(portal) {
      let text = 'Confirma a inativação do portal?'
      if(portal.status === 0) {
        text = 'Confirma a ativação do portal?'
      }
      await this.$swal.fire({
        text: text,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.post('/admin/portal/toggle-status', {
            portal_id: portal.id,
          }).finally(() => {
            this.handleSearch(this.filters, 1);
            this.loading = false;
          })
        }
      });
    },
  },
  computed: {
    ...mapState('Portals', ['collection', 'spreaders'])
  }
}
</script>
<style lang="scss" scoped>

</style>
