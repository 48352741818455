<template>
  <div>
    <ul>
      <li><a href="#" @click.prevent="$router.push('/')">Início</a></li>
      <slot>
      </slot>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'breadcrumbs'
}
</script>
<style scoped lang="scss">
a {
  color: #666;
}

a:hover {
  color: var(--primary);
}

ul {
  display: flex;
  align-items: center;
  list-style: none;
  color: #666;
  font-size: .85rem;
  padding: 0;
  margin: 0;
}

ul li:last-child {
  font-weight: 600;
}

ul li:after {
  content: '>';
  margin: 0 5px;
}

ul li:last-child:after {
  content: '';
}
</style>
