<template>
  <div class="card bg-white">
    <div class="card-body">
      <div class="row">
        <div class="col-10">
          <span class="text-primary fw-bold">Filtros</span>
          <slot></slot>
        </div>

        <div class="col-2">
          <div class="d-flex flex-column mt-2">
            <button class="btn btn-primary btn-block mb-2" @click="$emit('search')">Buscar</button>
            <button class="btn btn-secondary btn-block" @click="$emit('clear')">Limpar</button>
            <slot name="buttons"></slot>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'filters'
}
</script>
